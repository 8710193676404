import { Typography } from '@mui/material';
import React from 'react';
import './App.css';
import NavBar from './NavBar';

function App() {
  return (
    <div className="App">

      {/* The app's main navbar. */}
      <NavBar />

      <Typography sx={{ m: 4 }}>Welcome to Orepath</Typography>
      <Typography sx={{ m: 4 }} style={{"display": "none"}}>REACT_APP_ENV is '{process.env.REACT_APP_ENV}'</Typography>

    </div>
  );
}

export default App;
