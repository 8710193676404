import React, { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { AccountInfo, InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser';

const NavBar = () => {

  // Set up state for this component for the active user's account.
  const [activeAccount, setActiveAccount] = useState<AccountInfo | null>(null);

  // Get the MSAL instance, and currently logged in accounts.
  const { instance, accounts } = useMsal();

  useEffect(() => {

    // Set up the first logged in account as the active one (if there is one).
    const account = accounts.length > 0 ? accounts[0] : null;
    setActiveAccount(account);

    if (account)
    {
      const func = async () => { 

        // Set up a request to silently get a token to access the API.
        const msalTokenRequest : SilentRequest = {
          account: account!,
          authority: process.env.REACT_APP_ADB2C_AUTHORITY,
          scopes: [process.env.REACT_APP_OREPATH_API_SCOPE]
        };

        try {

          // Fetch the access token.
          const result = await instance.acquireTokenSilent(msalTokenRequest);

          // Set up fetch options to include the token as a bearer token in the 
          // authorization header.
          const fetchHeaders = new Headers();
          const bearerHeader = `Bearer ${result.accessToken}`;
          fetchHeaders.append("Authorization", bearerHeader);
          const fetchOptions = { method: "POST", headers: fetchHeaders };

          // Call the API to verify the user.
          const response = await fetch(
            process.env.REACT_APP_OREPATH_API_URI + "Users/VerifyUser", 
            fetchOptions);
          if (!response.ok)
          {
            throw new Error("Request failed");
          }

          // Get the content of the verification request.
          // FIXME: Once we've verified the user, we can then update the 
          // component to display details about the user.
          const content = await response.json();
          console.log("User verification content: ", content);
        }
        catch (error) {
          console.log("error", error);

          if (error instanceof InteractionRequiredAuthError)
          {
            // The operation failed because the request for an access token
            // requires interaction from the user - eg their session has 
            // expired and they need to log in again. In this case initiate a
            // redirect login to acquire the token we need.
            // See https://learn.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=react
            instance.acquireTokenRedirect(msalTokenRequest);
          }
        }
      }
      func();
    }

  }, [instance, accounts]);

  return (
    <div>
      <AppBar position="static" sx={{ backgroundColor: "#114F8C" }}>
        <Toolbar>

          {/*
            Top-left app icon.
            Enclosed in a box to add padding.
            Size overridden to 50% for better display on 2x dpi screens.
          */}
          <Box sx={{ mr: 1 }}>
            <img src={process.env.PUBLIC_URL + '/OrepathGrayTab_80x80.png'} alt="Orepath Logo" width="40" height="40" />
          </Box>
          {/*
            Top-left title. 
            Setting flexGlow=1 on the title causes it to expand to fill 
            remaining space, therefore right-aligning the elements after it
            (ie, the box containing the login button etc).
          */}
          <Typography component="h1" variant="h6" flexGrow="1" align="left">
            Orepath Portal
          </Typography>

          <Box>

            {/* Elements displayed when no user is logged in. */}
            <UnauthenticatedTemplate>
              {/* The log in button. */}
              <Button variant="contained" onClick={() => instance.loginRedirect() }>Log In</Button>
            </UnauthenticatedTemplate>

            {/* Elements displayed when a user is logged in. */}
            <AuthenticatedTemplate>
                {/* The user's name. */}
                <Typography display="inline" sx={{ mr: 2 }}>{activeAccount?.name}</Typography>
                {/* The log out button. */}
                <Button variant="contained" onClick={() => instance.logoutRedirect() }>Log Out</Button>
            </AuthenticatedTemplate>

          </Box>

        </Toolbar>
      </AppBar>
    </div>
  )
}

export default NavBar;
