import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// We make use of MSAL (Microsoft Authentication Library) to handle 
// authentication against our Azure AD B2C application.
import { MsalProvider } from "@azure/msal-react";
import { Configuration,  PublicClientApplication } from "@azure/msal-browser";

// Set up the MSAL configuration.
const msalConfiguration : Configuration = {
  auth: {
    clientId: process.env.REACT_APP_ADB2C_APPLICATION_ID,
    authority: process.env.REACT_APP_ADB2C_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_ADB2C_AUTHORITY_DOMAIN]
  },
  cache: {
    // Use local storage to cache the info - the alternative is sessionStorage,
    // but localStorage allows SSO between tabs.
    cacheLocation: 'localStorage'
  },
}

// Get the MSAL instance, used to handle logging in.
const msalInstance = new PublicClientApplication(msalConfiguration);

// Set up handler for after a login redirect response.
msalInstance.handleRedirectPromise()
  .then(function (response) {
    // Successful login from a redirect flow.
  })
  .catch((error) => {
    // An error occurred while logging in.
    console.log(error);

    // If we have previously signed in using eg a Microsoft account with 
    // user@example.com and now try to sign in using a Google account with the
    // same user@example.com, this will produce an error like the following:

    // AADB2C99001: This user already exists, and profile 'AAD-UserWriteUsingAlternativeSecurityId' does not allow the same user to be created again.
    // Correlation ID: 5dae022d-1059-43e0-a4fa-0f1c3e871837
    // Timestamp: 2023-04-06 03:05:30Z

    // Check for this error and display a clearer error message.
    if (error.errorMessage.includes("AADB2C99001: This user already exists"))
    {
      alert(
        "You are unable to log in with the same email address for both a Microsoft and Google account.\n\n" +
        "Please use your other account.")
    }
    else
    {
      // Otherwise display the error message.
      alert(error.errorMessage);
    }
  });

// Render the app.
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* Include the MSAL provider to insert context into the app. */}
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
